@import "./keyframes.scss";
@import "./hamburger.scss";
@import "./fonts.scss";
@import "./transitions.scss";
@import "./variables.scss";
@import "./reset.scss";
@import "~normalize.css/normalize.css";

*:before, *:after, * {
  box-sizing: border-box;
}
html, body {
  height: 100vh;
  overflow: hidden; // scrolling is handled inside ScrollContainer in Layout.tsx
  font-size: 14px;
  line-height: 23px;
  font-family: "Montserrat";
  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 23px;
  }
}

body.menu-open {
  #layout-container {overflow-y: hidden !important}
} //not proud but works

.__react_component_tooltip {
  box-shadow: 0 2px 8px rgba(0,0,0,.15) !important;
}

h1 {
  font-size: 2.42rem; // 34px
  line-height: 3.21rem; // 44px
  @media screen and (max-width: 960px) {
    font-size: 1.57rem; // ~22px
    line-height: 2.28rem; // ~32px
  }
}

h2 {
  font-size: 1.85rem; // 26px
  line-height: 2.57rem; // 36px
  @media screen and (max-width: 960px) {
    font-size: 1.42rem; // ~20px
    line-height: 2rem; // ~28px
  }
}

h3 {
  font-size: 1.71rem; // 24px
  line-height: 2.42rem; // 34px
  @media screen and (max-width: 960px) {
    font-size: 1.28rem; // 18px
    line-height: 2rem; // ~28px
  }
}

h4 {
  font-size: 1.42rem; // 20px
  line-height: 2.14rem; // 30px
  @media screen and (max-width: 960px) {
    font-size: 1.14rem; // 16px
    line-height: 1.85rem; // ~26px
  }
}

h5 {
  font-size: 1.14rem; // 16px
  line-height: 1.85rem; // 26px
  @media screen and (max-width: 960px) {
    font-size: 1rem; // 14px
    line-height: 1.64rem; // ~23px
  }
}

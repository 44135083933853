.notification-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.notification-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
  transform: translateX(0);
}
.notification-exit {
  opacity: 1;
  transform: translateX(0);
}
.notification-exit-active {
  opacity: 0;
  transition: all 500ms ease-in;
  transform: translateX(-100%);
}
